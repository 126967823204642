import styles from "./index.module.scss";
import nft1 from "../../assets/img/nft-image1.png";
import { NftWithCollection, useUserNftsQuery } from "../../hooks/useNftCollections";
import { Spinner } from "../../components/Spinner";
import UserCollectionCard from "../../components/UserCollectionCard";
import { extractCounterFromTokenName } from "../../lib/utils/nft";

const UserCollections = () => {
  const { data: userNfts, isLoading } = useUserNftsQuery();

  const groupedNftsMap: Record<string, NftWithCollection[]> = userNfts?.reduce((acc: Record<string, NftWithCollection[]>, nft) => {
    if (nft.collection === undefined) {
      return acc;
    }

    const nftCounter = extractCounterFromTokenName(nft.tokenName, "hex", nft.collection.prefixLength);
    const key = `${nft.currencySymbol}.${nftCounter}`;

    return {
      ...acc,
      [key]: [ ...(acc[key] || []), nft ],
    };
  }, {}) ?? {};

  const renderNfts = () => {
    return Object.entries(groupedNftsMap).map(([ key, nfts ]) => {
      const tokenNames = nfts.map((nft) => nft.tokenName);

      return (
        <UserCollectionCard
          key={key}
          tokenNames={tokenNames}
          collection={nfts[0].collection}
          image={nft1}
        />
      );
    });
  };

  return (
    <div className={styles.nfts__wrapper}>
      <div className={styles.nfts__container}>
        <div className={styles.nfts__heading}>
          <div>
            <div>
              <h3 className={styles.nfts__title}>Your Collections</h3>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.nftcard__container}>
        {isLoading
          ? <Spinner color="grey" />
          : <div className={styles.nfts__nftcard}>{renderNfts()}</div>
        }
      </div>
    </div>
  );
};

export default UserCollections;
