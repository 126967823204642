import styles from "./index.module.scss";
import nft1 from "../../assets/img/nft-image1.png";
import { useUserNftsQuery } from "../../hooks/useNftCollections";
import UserNftCard from "../../components/UserNftCard";
import { Spinner } from "../../components/Spinner";
import { extractCounterFromTokenName } from "../../lib/utils/nft";
import { Link, useLocation } from "react-router-dom";

type YourNftsProps = {
  currencySymbol: string;
  counter: number;
};

const YourNfts = ({ currencySymbol, counter }: YourNftsProps) => {
  const { data: userNfts, isLoading } = useUserNftsQuery();
  const location = useLocation();

  const collectionNfts = userNfts?.filter((nft) => {
    const cs = nft.currencySymbol;
    const tn = nft.tokenName;
    const nftCounter = extractCounterFromTokenName(
      tn,
      "hex",
      nft.collection.prefixLength
    );

    return cs === currencySymbol && counter === nftCounter;
  });

  const collectionName =
    collectionNfts && collectionNfts.length > 0
      ? collectionNfts[0].collection.name
      : "";

  const renderNfts = () => {
    return collectionNfts?.map(({ currencySymbol, tokenName, collection }) => {
      return (
        <UserNftCard
          key={`${currencySymbol}${tokenName}`}
          tokenName={tokenName}
          collection={collection}
          image={nft1}
        />
      );
    });
  };

  const title = isLoading
    ? ""
    : collectionName
      ? `${collectionName}`
      : "No NFTs found";

  return (
    <div className={styles.nfts__wrapper}>
      <div className={styles.nfts__container}>
        <div className={styles.nfts__heading}>
          <div>
            <div>
              <h3 className={styles.nfts__title}>
                <Link
                  to={{
                    pathname: location.pathname,
                    search: "",
                  }}
                >
                  Your Collections
                </Link>
                <span>{` / ${title}`}</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.nftcard__container}>
        {isLoading ? (
          <Spinner color="grey" />
        ) : (
          <div className={styles.nfts__nftcard}>{renderNfts()}</div>
        )}
      </div>
    </div>
  );
};

export default YourNfts;
