import styles from "./index.module.scss";
import { ReactSVG } from "react-svg";
import twitter from "../../../assets/img/twitter.svg";
import tiktok from "../../../assets/img/tiktok.svg";
import instagram from "../../../assets/img/instagram.svg";
import discord from "../../../assets/img/discord.svg";
import telegram from "../../../assets/img/telegram.svg";
import FooterBottom from "./FooterBottom";

export const Footer = () => {
  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <ul className={styles.socials}>
          <li>
            <a href="https://twitter.com/kwarxs" target="_blank" rel="noreferrer">
              <ReactSVG src={twitter} />
            </a>
          </li>
          <li>
            <a href="https://www.tiktok.com/@kwarxs" target="_blank" rel="noreferrer">
              <ReactSVG src={tiktok} />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/kwarxs_dao" target="_blank" rel="noreferrer">
              <ReactSVG src={instagram} />
            </a>
          </li>
          <li>
            <a href="https://discord.gg/VTvkqTGz8X" target="_blank" rel="noreferrer">
              <ReactSVG src={discord} />
            </a>
          </li>
          <li>
            <a href="https://t.me/+UELQXXf6clQ1MjY8" target="_blank" rel="noreferrer">
              <ReactSVG src={telegram} />
            </a>
          </li>
        </ul>
        <FooterBottom />
      </div>
    </div>
  );
};
