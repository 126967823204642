import { Layout } from "../../components/layout";
import { Hero } from "./hero";
import { KwarxsNfts } from "./kwarxsNfts";
import MembershipBenefits from "./membershipBenefits";
import Faq from "./q&a";
import Metrics from "./metrics";

export const Landing = () => {
  return (
    <Layout mlmType="KWARXS">
      <div className="container">
        <Hero />
        <MembershipBenefits />
        <KwarxsNfts />
        <Metrics />
        <Faq />
      </div>
    </Layout>
  );
};
