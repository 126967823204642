import { Landing } from "./pages/landing";
import { createBrowserRouter } from "react-router-dom";
import MarketplacePage from "./pages/marketplace";
import TermsPage from "./pages/policies/terms";
import YourNftsPage from "./pages/yourNfts";
import AdminPage from "./pages/admin";
import RhinoxMarketplacePage from "./pages//rhinox";
import RootLayout from "./RootLayout";
import PrivacyPage from "./pages/policies/privacy";
import { getConfig } from "./config";

const testnetRoutes = [
  {
    path: "/",
    element: <Landing />,
    errorElement: <div>404</div>,
  },
  {
    path: "/rhinox",
    element: <RhinoxMarketplacePage />,
  },
  {
    path: "/marketplace",
    element: <MarketplacePage />,
  },
  {
    path: "your-nfts",
    element: <YourNftsPage />,
  },
  {
    path: "/admin",
    element: <AdminPage />,
  },
];

const mainnetRoutes = [
  {
    path: "/",
    element: <RhinoxMarketplacePage />,
  },
];

const isMainnet = getConfig().cardanoNetwork === "Mainnet";

export const router = createBrowserRouter([
  {
    element: <RootLayout />,
    children: [
      ...(isMainnet ? mainnetRoutes : testnetRoutes),
      {
        path: "/terms",
        element: <TermsPage />,
      },
      {
        path: "/privacy",
        element: <PrivacyPage />,
      },
    ],
  },
]);
