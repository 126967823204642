import { AddressDetails, getAddressDetails, TxHash, WalletApi } from "lucid-cardano";
import { DefaultRequestParams, UserCancelledError, UserInfo, Wallet, WalletType } from "..";
import { postTxSubmit } from "../api/generated/client";

/**
 * Returns the used addresses of the wallet if present. Otherwise, the unused
 * addresses are returned (this happens when an account has no transactions).
 * @param walletApi The wallet CIP-30 API
 * @returns List of addresses
 */
export const getWalletAddresses = async (walletApi: WalletApi): Promise<string[]> => {
  const usedAddresses = await walletApi.getUsedAddresses();
  const unusedAddresses = await walletApi.getUnusedAddresses();

  return usedAddresses.length > 0 ? usedAddresses : unusedAddresses;
};

/**
 * Returns the primary address of the wallet (first of either used or unused addresses).
 * @param walletApi The wallet CIP-30 API
 * @returns The primary wallet address (or null)
 */
export const getPrimaryWalletAddress = async (walletApi: WalletApi): Promise<AddressDetails | null> => {
  const addresses = await getWalletAddresses(walletApi);
  const primaryAddress = addresses[0];

  if (!primaryAddress) {
    throw new Error("No wallet address found");
  }

  return getAddressDetails(primaryAddress);
};

export const isWalletAvailable = (walletType: WalletType): boolean => {
  if (!window.cardano) {
    return false;
  }
  return Object.keys(window.cardano).includes(walletType.toLowerCase());
};

/**
 * Returns a list of available wallets and their availability status
 */
export const getWalletAvailability = () => {
  return {
    nami: isWalletAvailable("Nami"),
    eternl: isWalletAvailable("Eternl"),
    vespr: isWalletAvailable("VESPR"),
    lace: isWalletAvailable("Lace"),
    tokeo: isWalletAvailable("Tokeo"),
  };
};


/** Get the first collateral UTxO of the user's wallet
 * @returns Cbor encoded TxOutRef
 */
export const getWalletCollateral = async (walletApi: WalletApi) => {
  const getCollateral =
    walletApi.getCollateral ?? walletApi.experimental.getCollateral;
  const collateralUtxos = await getCollateral();

  if (!collateralUtxos || collateralUtxos.length === 0) {
    throw new Error("No collateral set for the connected wallet");
  }

  return collateralUtxos[0];
};


/** Get the default request params required by the backend for generating transactions
 * @returns Default request params
 */
export const getDefaultRequestParams = async (
  wallet: Wallet,
  user: UserInfo | null,
): Promise<DefaultRequestParams> => {
  const addresses = await getWalletAddresses(wallet.api);
  const collateral = await getWalletCollateral(wallet.api);

  return {
    addresses,
    change: user?.walletAddress.hex ?? wallet?.hex,
    checkCollateral: true,
    collateral,
  };
};

export const signAndSubmitTx = async (
  walletApi: WalletApi,
  unsignedTx: string
): Promise<TxHash> => {
  try {
    const witnesses = await walletApi.signTx(unsignedTx, true);

    const res = await postTxSubmit({
      unsignedTx,
      witnesses,
    });

    if (res.status !== 200) {
      throw new Error(`Failed to submit transaction: ${res.data}`);
    }

    return res.data;
  } catch (error: unknown) {
    if (error instanceof Error && "code" in error && error.code === 2 && "info" in error) {
      throw new UserCancelledError(error.info as string);
    }
    if (error instanceof Error) {
      throw error;
    }
    throw new Error("An unknown error occurred");
  }
};
