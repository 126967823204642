import styles from "./index.module.scss";
import { Button } from "../button";
import { Spinner } from "../Spinner";
import { useBuyNftMutation } from "../../hooks/useNftCollections";
import { makeTransactionUrl } from "../../lib/utils/transaction";
import { useToastMessage } from "../../hooks/useToastMessage";
import { Collection } from "../../lib";
import BlockpassModal from "../BlockpassModal";
import { useState } from "react";

type CollectionCardProps = {
  collection: Collection;
  imageUrl: string;
};

const CollectionCard = ({
  collection: { name, currencySymbol, remaining, apy, price },
  imageUrl,
}: CollectionCardProps) => {
  const toast = useToastMessage();
  const [ showBlockpassModal, setShowBlockpassModal ] = useState(false);
  const { mutateAsync: buyNft, isPending: isBuyingNft } = useBuyNftMutation();

  const onBuyNft = async () => {
    try {
      const buyTxHash = await buyNft({ currencySymbol, mlmType: "KWARXS" });

      toast.success(
        <>
          NFT purchase{" "}
          <a
            href={makeTransactionUrl(buyTxHash)}
            target="_blank"
            rel="noreferrer"
          >
            transaction
          </a>{" "}
          submitted successfully. You should see the NFT in your wallet shortly.
        </>
      );
    } catch (error: any) {
      if (typeof error.message === "string" && error.message.includes("KYC")) {
        setShowBlockpassModal(true);
      } else {
        toast.error(error);
      }
    }
  };

  return (
    <div className={styles.nftCard__container}>
      <div className={styles.nftCard__img}>
        <img src={imageUrl} alt="nft" />
      </div>
      <h4 className={styles.nftCard__title}>
        <span className={styles.nftCard__text}>{name}</span>
      </h4>
      <table className={styles.nftCard__details}>
        <tbody>
          <tr>
            <td>Availability</td>
            <td>{remaining}</td>
          </tr>
          <tr>
            <td>Performance</td>
            <td>{apy}% APY</td>
          </tr>
          <tr>
            <td>Price</td>
            <td>{price.toString()} EUR</td>
          </tr>
        </tbody>
      </table>
      <div className={styles.nftCard__button_container}>
        <Button
          disabled={isBuyingNft}
          className={styles.nftCard__button}
          onClick={onBuyNft}
        >
          {isBuyingNft ? <Spinner width={20} /> : <>Buy Now</>}
        </Button>
      </div>
      <BlockpassModal
        isOpen={showBlockpassModal}
        onClose={() => setShowBlockpassModal(false)}
      />
    </div>
  );
};

export default CollectionCard;
