import styles from "../index.module.scss";

export const PrivacyText = () => {
  return (
    <div className={styles.policy__container}>
      <h1>KWARXS Privacy Policy</h1>
      <section>
        <h2>Introduction</h2>
        <p>This privacy policy explains how we collect, use, and protect your information when you use our NFT marketplace.</p>
      </section>
      <section>
        <h2>Information We Collect and Store</h2>
        <h3>On Our Platform</h3>
        <p>We store only:</p>
        <ul>
          <li>Public Cardano wallet addresses</li>
          <li>Account IDs</li>
          <li>Records of NFT purchases and other transactions associated with those addresses</li>
        </ul>
        <h3>Local Device Storage</h3>
        <p>We store the following information in your browser's localStorage:</p>
        <ul>
          <li>Wallet addresses and public key hashes</li>
          <li>Account IDs</li>
          <li>Wallet type and configuration</li>
          <li>Network type (mainnet/testnet)</li>
        </ul>
        <p>This local data is stored only on your device and is not transmitted to our servers (with the exception of wallet addresses used in transactions).</p>
        <h3>Third-Party Services</h3>
        <h4>MLM Soft</h4>
        <ul>
          <li>We share order information with MLM Soft for transaction processing</li>
          <li>User registration and account data is handled directly by MLM Soft</li>
          <li>We do not store MLM Soft account information on our servers</li>
          <li>For MLM Soft's data handling practices, please refer to their privacy policy at <a href="https://www.mlmsoft.com/mlmsoftware/privacy-policy" target="_blank" rel="noreferrer">https://www.mlmsoft.com/mlmsoftware/privacy-policy</a></li>
        </ul>
        <h4>Blockpass</h4>
        <ul>
          <li>KYC verification is performed through Blockpass</li>
          <li>We receive only verification status from Blockpass</li>
          <li>We do not store any personal identification documents or KYC information</li>
          <li>For details on how Blockpass handles your data, please see their privacy policy at <a href="https://www.blockpass.org/privacy/" target="_blank" rel="noreferrer">https://www.blockpass.org/privacy/</a></li>
        </ul>
      </section>
      <section>
        <h2>How We Use Your Information</h2>
        <p>We use the collected information to:</p>
        <ul>
          <li>Process NFT purchases</li>
          <li>Verify wallet ownership</li>
          <li>Confirm KYC status</li>
          <li>Maintain transaction records</li>
          <li>Improve our services</li>
        </ul>
      </section>
      <section>
        <h2>Data Security</h2>
        <ul>
          <li>We only store public blockchain data and purchase records</li>
          <li>Private keys and seed phrases are never collected or stored</li>
          <li>Local storage data is maintained only on your device</li>
          <li>We implement industry-standard security measures to protect our records</li>
        </ul>
      </section>
      <section>
        <h2>Your Rights and Control</h2>
        <p>You can:</p>
        <ul>
          <li>Clear locally stored data by disconnecting your wallet or clearing browser data</li>
          <li>Request information about what purchase records we hold for your wallet address</li>
          <li>Request deletion of non-essential data associated with your wallet</li>
          <li>Manage your data with MLM Soft and Blockpass directly through their platforms</li>
        </ul>
      </section>
      <section>
        <h2>Changes to This Policy</h2>
        <p>We may update this policy to reflect changes in our practices or regulatory requirements. Significant changes will be communicated through our platform.</p>
      </section>
      <section>
        <h2>Third-Party Links and Services</h2>
        <p>Our platform contains links to third-party services (MLM Soft and Blockpass). We are not responsible for their privacy practices. We encourage you to read their privacy policies.</p>
      </section>
      <section>
        <h2>Contact Us</h2>
        <p>For questions about this privacy policy or our data practices, contact us at: <a href="https://linktr.ee/Kwarxs" target="_blank" rel="noreferrer">linktr.ee/Kwarxs</a></p>
      </section>
      <p className={styles.policy__lastUpdated}>Last Updated: 2025/02/21</p>
    </div>
  );
};
