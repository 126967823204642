import styles from "./index.module.scss";
import { Button } from "../../../components/button";
import { Spinner } from "../../../components/Spinner";
import { useAuthContext } from "../../../context/AuthContext";
import { useBuyNftMutation } from "../../../hooks/useNftCollections";
import { makeTransactionUrl } from "../../../lib/utils/transaction";
import { useToastMessage } from "../../../hooks/useToastMessage";
import { Collection } from "../../../lib";
import RhinoxCollectionVideo from "../../../assets/Rhinox_collection.mp4";
import RhinoxCollectionPoster from "../../../assets/Rhinox_collection_poster.jpg";

type CollectionCardRhinoxProps = {
  collection: Collection;
};

const CollectionCardRhinox = ({
  collection: {
    name,
    currencySymbol,
    remaining,
    price,
  },
}: CollectionCardRhinoxProps) => {
  const { connectedWallet, nfteamUser } = useAuthContext();
  const toast = useToastMessage();

  const { mutateAsync: buyNft, isPending: isBuyingNft } = useBuyNftMutation();

  const onBuyNft = async () => {
    try {
      if (!connectedWallet) {
        throw new Error("Please connect wallet to buy an NFT.");
      }

      if (!nfteamUser) {
        throw new Error("Please sign up to buy an NFT.");
      }

      const buyTxHash = await buyNft({ currencySymbol, mlmType: "NFTEAM" });

      toast.success(
        <>
            NFT purchase{" "}
          <a href={makeTransactionUrl(buyTxHash)} target="_blank" rel="noreferrer">
              transaction
          </a>{" "}
            submitted successfully. You should see the NFT in your
            wallet shortly.
        </>
      );
    }
    catch (error: any) {
      toast.error(error);
    }
  };

  return (
    <div className={styles.nftCard__container}>
      <video
        className={styles.nftCard__video}
        autoPlay
        loop
        muted
        playsInline
        poster={RhinoxCollectionPoster}
      >
        <source
          src={RhinoxCollectionVideo}
          type="video/mp4"
        />
      </video>
      <div className={styles.nftCard__details}>
        <div>
          <h4 className={styles.nftCard__title}>
            <span className={styles.nftCard__text}>{name}</span>
          </h4>
        </div>
        <div>
          <table className={styles.nftCard__table}>
            <tbody>
              <tr>
                <td>Availability</td>
                <td>{remaining}</td>
              </tr>
              <tr>
                <td>Price</td>
                <td>{price.toString()} EUR</td>
              </tr>
            </tbody>
          </table>
          <div className={styles.nftCard__button_container}>
            <Button
              disabled={isBuyingNft}
              className={styles.nftCard__button} onClick={onBuyNft}
            >{isBuyingNft ? <Spinner width={20} /> : <>Mint</>}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectionCardRhinox;
