import { Layout } from "./layout";
import RhinoxMarketplace from "./rhinoxMarketplace";

function RhinoxMarketplacePage() {
  return (
    <Layout>
      <RhinoxMarketplace />
    </Layout>
  );
}

export default RhinoxMarketplacePage;
