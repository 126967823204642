import { SubmitHandler, useForm } from "react-hook-form";
import {
  useAddNftsMutation,
  useCollectionQuery,
} from "../../hooks/useNftCollections";
import { Input } from "../../components/input";
import { Button } from "../../components/button";
import { Modal } from "../../components/Modal";
import {
  fetchMimeType,
  validateImageMimeType,
  validateURL,
} from "../../lib/utils/url";
import styles from "./index.module.scss";
import { useToastMessage } from "../../hooks/useToastMessage";
import { mkNamesForNft } from "../../lib/utils/nft";

type AddNftsModalProps = {
  collectionName: string;
  currencySymbol: string;
  prefixLength: number;
  isOpen: boolean;
  onClose: () => void;
};

type FormValues = {
  description: string;
  image: string;
  mediaType: string;
  prefix: string;
  quantity: string;
};

/**
 * A form component for adding new NFTs to the marketplace.
 */
const AddNftsModal = ({
  collectionName,
  currencySymbol,
  prefixLength,
  isOpen,
  onClose,
}: AddNftsModalProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();
  const { data: collection } = useCollectionQuery({ currencySymbol });
  const { mutateAsync: addNfts } = useAddNftsMutation();
  const toast = useToastMessage();

  if (!collection) return null;

  const { name, tokenName } = mkNamesForNft(collection.name, collection.total + 1);

  const onImageUrlBlur = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const imageUrl = e.target.value;
    if (!validateURL(imageUrl)) return;

    const mediaType = await fetchMimeType(imageUrl);
    if (!mediaType) return;

    setValue("mediaType", mediaType || "", { shouldValidate: true });
  };

  const onSubmit: SubmitHandler<FormValues> = async ({
    description,
    image,
    mediaType,
    quantity,
  }) => {
    try {
      await addNfts({
        collection,
        description,
        image,
        mediaType,
        quantity: parseInt(quantity),
      });

      toast.success("NFTs added successfully");
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      toast.error("Failed to add NFTs. See console for more details");
    }
  };

  return (
    <Modal showModal={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <h2>Add NFTs</h2>
        <Input
          label="Currency Symbol"
          type="text"
          value={currencySymbol}
          disabled
        />
        <Input label="Collection Name" type="text" value={collectionName} disabled />
        <Input
          label="Name"
          type="text"
          value={name}
          disabled
        />
        <Input
          {...register("description")}
          label="Description"
          type="text"
          error={errors.description?.message}
        />
        <Input
          {...register("image", {
            required: "Image URL is required",
            maxLength: { value: 64, message: "Image URL must not exceed 64 characters" },
            validate: (value) =>
              validateURL(value) || "Image URL is not a valid URL",
          })}
          label="Image URL"
          type="text"
          onBlur={onImageUrlBlur}
          error={errors.image?.message}
        />
        <Input
          {...register("mediaType", {
            required: "Image media type is required",
            validate: (value) =>
              validateImageMimeType(value) ||
              "Media type is not valid image type such as image/jpeg, image/png, etc.",
          })}
          label="Image Media type"
          type="text"
          defaultValue="image/jpeg"
          error={errors.mediaType?.message}
        />
        <Input
          value={tokenName}
          label="Token Name"
          type="string"
          disabled
        />
        <Input
          {...register("quantity", {
            required: "Quantity is required",
            min: { value: 1, message: "Quantity must be at least 1" },
          })}
          label="Quantity"
          type="number"
          min="1"
          defaultValue={1}
          error={errors.quantity?.message}
        />
        <Button type="submit">Submit</Button>
      </form>
    </Modal>
  );
};

export default AddNftsModal;
