import styles from "./index.module.scss";

export const KwarxsNfts = () => {
  return (
    <div className={styles.kwarxsNfts}>
      <div className={styles.title}>
        <h2>Kwarxs NFT</h2>
        <h2 className={styles.soon}>(Dropping Soon!)</h2>
      </div>
      <div className={styles.benefits}>
        <div className={styles.benefit}>
          <h4>Take Your Place In The Sun</h4>
          <p>
            NFTs from this collection provide Kwarxs-enabled DAO
            membership, but that’s not all. These first-of-their-kind NFTs also
            represent fractionalized ownership of a profitable, grid-connected
            solar energy farm located in Roebnitz, Germany.
          </p>
        </div>
        <div className={styles.benefit}>
          <h4>Harness The Power Of The DAO</h4>
          <p>
            Kwarxs NFT holders own and profit from this business and may
            participate in its cooperative management through the innovative
            Kwarxs DAO platform… all while earning an average APY of 10%-25%.
          </p>
        </div>
      </div>
      <div className={styles.call}>Together, We (are) Matter!</div>
    </div>
  );
};
