import styles from "../index.module.scss";

export const TermsText = () => {
  return (
    <div className={styles.policy__container}>
      <h1>Terms and Conditions (T&Cs) of the KWARXS Project</h1>
      <h2>1. General Scope</h2>
      <p>The KWARXS Project (hereinafter referred to as "the Project") is a community-driven initiative designed for entertainment, art and educational purposes only. By accessing or using any part of this website, platform, or related services, you agree to be bound by these Terms and Conditions (T&Cs). If you do not agree, you must immediately cease all use of the Project’s services.</p>
      <p>This Project does not provide financial advice, investment recommendations, or guarantees of any kind. Participation in cryptocurrency or NFT-related activities is inherently risky, speculative, and subject to significant financial loss.</p>
      <h2>2. Disclaimer of Liability</h2>
      <h3>No Financial Responsibility</h3>
      <ul>
        <li><strong>No Advice:</strong> The Project does not provide financial, legal, or investment advice. All content and materials are for informational purposes only. You are solely responsible for conducting your own research and making independent decisions.</li>
        <li><strong>No Guarantees:</strong> The Project makes no guarantees regarding the value, performance, or usability of any NFTs, cryptocurrencies, or other digital assets associated with this platform.</li>
      </ul>
      <h3>Assumption of Risk</h3>
      <ul>
        <li>Cryptocurrency and NFT investments are highly volatile and speculative. By participating in the Project, you acknowledge that you fully understand these risks and accept sole responsibility for any outcomes.</li>
        <li>The Project is not liable for any damages, losses (financial or otherwise), theft (including data theft), technical failures, or the loss of private keys associated with your use of this platform.</li>
        <li>There is no claim to remuneration or similar. We assume no liability. We reserve the right to change, modify, and update the terms and conditions at any time. There is no claim to profits.</li>
      </ul>
      <h3>No Control Over Third Parties</h3>
      <p>The Project operates on decentralized blockchain networks over which it has no control. We are not responsible for issues arising from third-party platforms (e.g., wallets, marketplaces) or blockchain malfunctions.</p>
      <h2>3. Use at Your Own Risk</h2>
      <h3>Self-Responsibility</h3>
      <ul>
        <li>All actions taken on this platform—including but not limited to purchasing NFTs—are done entirely at your own risk. The Project provides no assurances regarding the success or functionality of its services.</li>
        <li>Once acquired, NFTs cannot be reversed, refunded, or deleted but may be burned according to blockchain protocols.</li>
      </ul>
      <h3>Technical Risks</h3>
      <p>The Project is not responsible for technical issues such as blockchain interruptions, smart contract errors, wallet malfunctions, or other unforeseen events that may impact your ability to access or use NFTs.</p>
      <h2>4. User Eligibility and Responsibility</h2>
      <h3>Age Requirement</h3>
      <ul>
        <li>To use this platform or participate in any activities associated with the Project, you must be at least 18 years old. By accessing the platform, you confirm that you meet this age requirement.</li>
      </ul>
      <h3>Wallet Management</h3>
      <ul>
        <li>You are solely responsible for managing your cryptocurrency wallet(s), securing your private keys, and ensuring compatibility with the blockchain used by the Project. Loss of access to your wallet may result in permanent loss of assets.</li>
      </ul>
      <h2>5. Transactions and Payments</h2>
      <h3>Cryptocurrency Only</h3>
      <ul>
        <li>All transactions on this platform are conducted exclusively in cryptocurrency (e.g., ADA tokens). The Project does not accept fiat currency payments under any circumstances.</li>
      </ul>
      <h3>No Refunds or Reversals</h3>
      <ul>
        <li>All purchases are final. NFTs cannot be returned or refunded under any circumstances once a transaction is completed on the blockchain.</li>
      </ul>
      <h2>6. Modification of Terms</h2>
      <p>The Project reserves the right to amend these T&Cs at any time without prior notice. It is your responsibility to review these terms periodically for updates. Continued use of the platform constitutes acceptance of any revised terms.</p>
      <h2>7. Governing Law and Jurisdiction</h2>
      <p>These T&Cs shall be governed by and construed in accordance with the laws of United Arab Emirates, excluding conflict-of-law principles. Any disputes arising from these terms shall be resolved exclusively in the courts located in United Arab Emirates.</p>
      <h2>8. Severability Clause</h2>
      <p>If any provision within these T&Cs is deemed invalid or unenforceable by a court of law, all remaining provisions shall remain in full force and effect. An invalid provision will be replaced with one that closely reflects its original intent while remaining enforceable under applicable law.</p>
      <h2>Final Warning: High-Risk Activity Disclaimer</h2>
      <p>Participation in cryptocurrency and NFT markets involves substantial risk and may result in significant financial loss. By using this platform, you acknowledge that you fully understand these risks and release the Project from all liability associated with your participation.</p>
    </div>
  );
};
