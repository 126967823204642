import React from "react";
import { Footer } from "./footer";
import { Header } from "../../../components/layout/header";
import styles from "./index.module.scss";

export const Layout = ({ children }: React.PropsWithChildren) => {
  return (
    <div className={styles.page_wrapper}>
      <Header mlmType="NFTEAM" showMenu={false} />
      <div className={styles.page_content}>{children}</div>
      <Footer />
    </div>
  );
};
