import { fromText } from "lucid-cardano";
import { GYAssetClass } from "../api/generated/models";

export const tokenNameLength = 32;

export const fromHex = (hex: string): string => {
  let res = "";
  for (let i = 0; i < hex.length; i += 2)
    res += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
  return res;
};

export const fromHexTobytes = (hex: string): Uint8Array => {
  const bytes = new Uint8Array(hex.length / 2);
  for (let i = 0; i < hex.length; i += 2) {
    bytes[i / 2] = parseInt(hex.substr(i, 2), 16);
  }
  return bytes;
};

export const mkTokenName = (prefix: string, counter: number): string => {
  const padLeft = (x: string) => x.padStart(tokenNameLength - prefix.length, "0");
  const str: string = prefix + padLeft(counter.toString());
  return str;
};

export const mkPaddedCounter = (length: number, counter: number): string => {
  const padLeft = (x: string) => x.padStart(length, "0");
  const str: string = padLeft(counter.toString());
  return str;
};

export const mkGYAssetClass = ({
  policyId,
  tokenName,
}: {
  policyId: string;
  tokenName: string;
}): GYAssetClass => {
  return `${policyId}.${tokenName}`;
};

/**
 * Converts a UTF-8 encoded string to a hexadecimal encoded string.
 * @param string UTF-8 encoded string
 * @returns Hex encoded string
 */
export const utf8ToHex = (string: string): string => {
  return fromText(string);
};
