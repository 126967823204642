import { Layout } from "../../../components/layout";
import { TermsText } from "./termsText";

const TermsPage = () => {
  return (
    <Layout mlmType="KWARXS">
      <TermsText />
    </Layout>
  );
};

export default TermsPage;
