import { Collection, PostNftsCreateNftBodyItem } from "..";
import { fromHex, mkPaddedCounter, mkTokenName, utf8ToHex } from "./encoding";

export const tokenNameLength = 32;
export const counterLength = 3;
export const prefixLength = tokenNameLength - counterLength;

export const mkNamesForNft = (collectionName: string, nftNumber: number) => {
  const abbreviation = abbreviateName(collectionName);
  const paddedNftNumberLength =
    tokenNameLength - counterLength - abbreviation.length - 2;
  const paddedNumber = mkPaddedCounter(paddedNftNumberLength, nftNumber);
  const tokenName = mkTokenName(`${abbreviation}-${paddedNumber}#`, 0);
  const tokenNameHex = utf8ToHex(tokenName);
  const name = collectionName + " #" + paddedNumber;

  return { name, tokenName, tokenNameHex };
};

export const mkPoolTokenName = (collection: Collection, counter: number) => {
  const abbreviation = abbreviateName(collection.name);
  const tokenName = mkTokenName(`${abbreviation}-pool--`, counter);
  const tokenNameHex = utf8ToHex(tokenName);

  return { tokenName, tokenNameHex };
};

/**
 * Takes a collection and details for NFTs to be minted and generates a list of
 * NFTs that each have unique identifying numbers.
 *
 * The token names will be composed of 3 parts:
 *   - An abbreviation of the collection name
 *   - A padded number that identifies the NFT within the collection
 *   - A padded counter that will be used for versioning
 *
 * Example:
 * For a collection named "My Fancy Collection" that has a total of 100 NFTs
 * already minted, the next set of NFTs will have token names:
 *   - "MFC-00101-0000000000000000000000"
 *   - "MFC-00102-0000000000000000000000"
 *   - "MFC-00103-0000000000000000000000"
 * @param collection
 * @param nftCount
 * @returns
 */
export const mkNextNfts = (params: {
  collection: Collection;
  image: string;
  mediaType: string;
  description?: string;
  quantity: number;
}): PostNftsCreateNftBodyItem[] => {
  const startingNumber = params.collection.total + 1;

  // Generate the token names with proper counters
  return Array.from({ length: params.quantity }, (_, i) => {
    const { tokenNameHex, name } = mkNamesForNft(
      params.collection.name,
      startingNumber + i
    );

    return {
      currencySymbol: params.collection.currencySymbol,
      description: params.description ?? "",
      image: params.image,
      mediaType: params.mediaType,
      name,
      tokenName: tokenNameHex,
    };
  });
};

/**
 * Extracts the counter from a token name as an integer.
 * E.g., "some-collection-0000000000000012" -> 12
 * @param tokenName The token name to extract the counter from
 * @param encoding The encoding of the token name
 * @param prefixLength The prefix length before the counter
 * @returns The counter as an integer
 * @throws If the token name does not have the expected format
 */
export const extractCounterFromTokenName = (
  tokenName: string,
  encoding: "utf8" | "hex",
  prefixLength: number
): number => {
  if (
    encoding === "utf8" &&
    tokenName.length === 64 &&
    tokenName.match(/^[0-9a-f]+$/)
  ) {
    // eslint-disable-next-line no-console
    console.warn(
      `'extractCounterFromTokenName' called with possible hex token name: '${tokenName}' and 'utf8' encoding. Did you mean to use 'hex' encoding?`
    );
  }

  const decodedTokenName = encoding === "utf8" ? tokenName : fromHex(tokenName);

  return parseInt(decodedTokenName.slice(prefixLength), 10);
};

function abbreviateName(name: string): string {
  const words = name.split(" ");
  return words.map((word) => word[0].toUpperCase()).join("");
}
