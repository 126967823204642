import styles from "./index.module.scss";
import telegram from "../../../../assets/img/telegram.svg";
import { ReactSVG } from "react-svg";
import FooterBottom from "../../../../components/layout/footer/FooterBottom";

export const Footer = () => {
  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <ul className={styles.socials}>
          <li>
            <a href="https://t.me/Rhinox_game_bot" target="_blank" rel="noreferrer">
              <ReactSVG src={telegram} />
              <span>RHINOX Game</span>
            </a>
          </li>
        </ul>
        <FooterBottom />
      </div>
    </div>
  );
};
