import { getConfig } from "../../config";

export const makeTransactionUrl = (txHash: string): string => {
  const { cardanoNetwork } = getConfig();
  const url = new URL("https://cexplorer.io");

  if (cardanoNetwork !== "Mainnet") {
    url.hostname = `${cardanoNetwork.toLowerCase()}.${url.hostname}`;
  }

  url.pathname = `/tx/${txHash}`;

  return url.toString();
};
