import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Collection, createRewardPools, extractClaimableAmount, getRewardNfts, getRewardPoolsByAsset, GYValue, RewardPoolWithUTxO, WalletNotConnectedError, withdrawReward } from "../lib";
import { fromHex, mkPaddedCounter, tokenNameLength } from "../lib/utils/encoding";
import { extractCounterFromTokenName, mkPoolTokenName } from "../lib/utils/nft";
import { useAuthContext } from "../context/AuthContext";

export type WithdrawRewardsParams = {
  currencySymbol: string;
  tokenNames: string[];
  rewardsPool: RewardPoolWithUTxO;
};

export const rewardPoolsQueryKeys = {
  rewardPoolNfts: "rewardPoolNfts",
  rewardPools: "rewardPool",
  rewardPoolsByCollection: "rewardPoolsByCollection",
};

const queryStaleTime = 1000 * 30; // 30 seconds

type UseClaimableRewardsQueryProps = {
  currencySymbol: string;
  nftCounter: number;
  nftQty: number;
}

export const useFindSuitableRewardsPoolQuery = ({ currencySymbol, nftCounter, nftQty }: UseClaimableRewardsQueryProps) => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: [ "claimableRewards", currencySymbol, nftCounter, nftQty ],
    queryFn: async () => {
      // Fetch the reward pool records from the backend
      const rewardNfts = await queryClient.fetchQuery({
        queryKey: [rewardPoolsQueryKeys.rewardPoolNfts],
        queryFn: getRewardNfts,
        staleTime: queryStaleTime,
      });

      const matchingPoolNfts = rewardNfts.filter((rewardNft) => {
        const poolCounter = extractCounterFromTokenName(rewardNft.rewardPoolNftCounter, "utf8", 0);
        const poolTokenName = fromHex(rewardNft.rewardPoolNftTokenName);
        const poolCounterFromTokenName = poolTokenName.slice(poolTokenName.length - rewardNft.rewardPoolNftCounter.length);

        // Only include pool NFTs with matching currency symbol, counter, and
        // consistent data
        if (rewardNft.rewardPoolNftCollection !== currencySymbol) return false;
        if (poolCounter !== nftCounter) return false;
        if (poolCounterFromTokenName !== rewardNft.rewardPoolNftCounter) return false;

        return true;
      });

      if (matchingPoolNfts.length === 0) {
        return null;
      }

      const promises = matchingPoolNfts.map((poolNft) => {
        // Fetch the actual matching reward pools from onchain
        return queryClient.fetchQuery({
          queryKey: [ rewardPoolsQueryKeys.rewardPools, poolNft.rewardPoolNftSymbol, poolNft.rewardPoolNftTokenName ],
          queryFn: () => {
            return getRewardPoolsByAsset({
              policyId: poolNft.rewardPoolNftSymbol,
              tokenName: poolNft.rewardPoolNftTokenName,
            });
          },
          staleTime: queryStaleTime,
        });
      });

      const results = await Promise.all(promises);

      const poolsWithSufficientValue = results.flat().filter((pool) => {
        const claimableRewards = extractClaimableAmount(pool);
        const poolValue = pool.poolUTxO[1];

        const rewardAssets = Object.keys(claimableRewards);

        return rewardAssets.every(asset => {
          if (!(asset in poolValue)) return false;

          if (typeof claimableRewards[asset] !== "number" || typeof poolValue[asset] !== "number") {
            return false;
          }

          const totalClaimableValue = claimableRewards[asset] * nftQty;

          return poolValue[asset] >= totalClaimableValue;
        });
      });

      // Select random reward pool to avoid congestion
      const pool = pickRandomFrom(poolsWithSufficientValue);

      return { pool, claimableAmount: extractClaimableAmount(pool, nftQty) };
    },
  });
};

type UseRewardPoolsQueryProps = {
  collectionCurrencySymbol: string;
}

export const useRewardPoolsQuery = ({ collectionCurrencySymbol }: UseRewardPoolsQueryProps) =>{
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: [ rewardPoolsQueryKeys.rewardPoolsByCollection, collectionCurrencySymbol ],
    staleTime: queryStaleTime,
    queryFn: async () => {
      // Fetch latest reward pool NFTs
      const rewardPoolNfts = await queryClient.fetchQuery({
        queryKey: [rewardPoolsQueryKeys.rewardPoolNfts],
        queryFn: getRewardNfts,
        staleTime: queryStaleTime,
      });

      // Find reward pool NFTs that match the collection currency symbol
      const matchingPoolNfts = rewardPoolNfts.filter((nft) => nft.rewardPoolNftCollection === collectionCurrencySymbol);

      // Fetch reward pools for each matching reward pool NFT.
      const promises = matchingPoolNfts.map((poolNft) => {
        return getRewardPoolsByAsset({
          policyId: poolNft.rewardPoolNftSymbol,
          tokenName: poolNft.rewardPoolNftTokenName,
        });
      });
      const results = await Promise.all(promises);

      return results.flat();
    },
  });
};

export const useRewardPoolNftsQuery = () => {
  return useQuery({
    queryKey: [rewardPoolsQueryKeys.rewardPoolNfts],
    queryFn: getRewardNfts,
  });
};

type AddRewardPoolMutationProps = {
  collection: Collection;
  rewardValue: GYValue;
  dividendsPerPool: number;
  poolQty: number;
  counter: number;
}

export const useAddRewardPoolMutation = () => {
  const queryClient = useQueryClient();
  const { connectedWallet, kwarxsUser } = useAuthContext();

  return useMutation({
    mutationFn: async ({
      collection,
      rewardValue,
      dividendsPerPool,
      poolQty,
      counter,
    }: AddRewardPoolMutationProps) => {
      if (!connectedWallet) {
        throw new WalletNotConnectedError();
      }

      const { tokenNameHex } = mkPoolTokenName(collection, counter);

      const params = {
        wallet: connectedWallet,
        user: kwarxsUser,
        counter:  mkPaddedCounter(tokenNameLength - collection.prefixLength, counter),
        dividendsPerPool,
        nftCs: collection.currencySymbol,
        poolCount: poolQty,
        poolTn: tokenNameHex,
        rewardValue,
      };

      return createRewardPools(params);
    },
    onSuccess: (_, { collection }) => {
      queryClient.invalidateQueries({ queryKey: [ rewardPoolsQueryKeys.rewardPoolsByCollection, collection.currencySymbol ]});
    },
  });
};

export const useWithdrawRewardsMutation = () => {
  const { connectedWallet, kwarxsUser } = useAuthContext();

  return useMutation({
    mutationFn: async ({ currencySymbol, tokenNames, rewardsPool }: WithdrawRewardsParams) => {
      if (!connectedWallet) {
        throw new WalletNotConnectedError();
      }

      return withdrawReward({
        wallet: connectedWallet,
        kwarxsUser,
        pool: rewardsPool,
        nftTokenNames: tokenNames,
        collectionPolicyId: currencySymbol,
      });
    },
  });
};

function pickRandomFrom<T>(arr: T[]): T {
  return arr[Math.floor(Math.random() * arr.length)];
}
