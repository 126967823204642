import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Nft, Collection, CreateCollection, getCollections, addCollections, addNft, buyNft, WalletNotConnectedError, MlmType, getUsersNfts } from "../lib";
import { mkNextNfts, prefixLength } from "../lib/utils/nft";
import { useAuthContext } from "../context/AuthContext";
import { useEffect } from "react";

export const collectionsQueryKeys = {
  collections: "collections",
};

const queryStaleTime = 1000 * 30; // 30 seconds

export const useCollectionsQuery = () => {
  return useQuery({
    queryKey: [collectionsQueryKeys.collections],
    staleTime: queryStaleTime,
    queryFn: getCollections,
  });
};

export const useCollectionQuery = ({
  currencySymbol,
}: {
  currencySymbol: string;
}) => {
  const collectionsQuery = useCollectionsQuery();
  return useQuery({
    queryKey: [ collectionsQueryKeys.collections, currencySymbol ],
    staleTime: queryStaleTime,
    queryFn: async () => {
      const collections = collectionsQuery.data;
      return collections?.find(
        (collection) => collection.currencySymbol === currencySymbol
      );
    },
    enabled: !!collectionsQuery.data,
  });
};

type AddCollectionsMutationParams = Omit<
  CreateCollection,
  "prefixLength" | "total" | "remaining"
>;

export const useAddCollectionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (params: AddCollectionsMutationParams) => {
      const collection = {
        ...params,
        prefixLength,
        total: 0,
        remaining: 0,
      };

      return addCollections([collection]);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [collectionsQueryKeys.collections],
      });
    },
  });
};

type AddNftsMutationParams = {
  collection: Collection;
  image: string;
  mediaType: string;
  description?: string;
  quantity: number;
};

export const useAddNftsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      collection,
      image,
      mediaType,
      description,
      quantity,
    }: AddNftsMutationParams) => {
      const nfts = mkNextNfts({
        collection,
        image,
        mediaType,
        description: description ?? "",
        quantity,
      });

      return addNft(nfts);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [collectionsQueryKeys.collections],
      });
    },
  });
};

export const useBuyNftMutation = () => {
  const queryClient = useQueryClient();
  const { connectedWallet, kwarxsUser, nfteamUser } = useAuthContext();

  return useMutation({
    mutationFn: async (params: { currencySymbol: string, mlmType: MlmType }) => {
      if (!connectedWallet) {
        throw new WalletNotConnectedError();
      }
      return buyNft({
        wallet: connectedWallet,
        currencySymbol: params.currencySymbol,
        user: params.mlmType === "KWARXS" ? kwarxsUser : nfteamUser,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [collectionsQueryKeys.collections],
      });
    },
  });
};

export type NftWithCollection = Nft & {
  collection: Collection;
};

export const useUserNftsQuery = () => {
  const collectionsQuery = useCollectionsQuery();
  const { connectedWallet } = useAuthContext();
  const queryClient = useQueryClient();

  // Ensure that the user's NFTs are refetched if the user changes their
  // connected wallet account
  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ["userNfts"]});
  }, [ connectedWallet, queryClient ]);

  return useQuery({
    queryKey: ["userNfts"],
    staleTime: queryStaleTime,
    queryFn: () => {
      if (!connectedWallet) {
        throw new WalletNotConnectedError();
      }

      const collections = collectionsQuery.data;
      if (!collections) return [];

      const promises = collections.map(async ({ currencySymbol }) => {
        return getUsersNfts({ walletApi: connectedWallet.api, currencySymbol });
      });

      return Promise.all(promises).then((results) => {
        const nfts = results.flat();
        const collectionsMap = collections?.reduce((acc, collection) => {
          return { ...acc, [collection.currencySymbol]: collection };
        }, {} as Record<string, Collection>);

        return nfts.map((nft) => {
          const collection = collectionsMap?.[nft.currencySymbol];
          return {
            ...nft,
            collection,
          } as NftWithCollection;
        });
      });
    },
    enabled: !!collectionsQuery.data,
  });
};
