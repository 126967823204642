import { Layout } from "../../../components/layout";
import { PrivacyText } from "./privacyText";

const PrivacyPage = () => {
  return (
    <Layout mlmType="KWARXS">
      <PrivacyText />
    </Layout>
  );
};

export default PrivacyPage;
