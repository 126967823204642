import { Link } from "react-router-dom";
import styles from "./index.module.scss";

const FooterBottom = () => {
  return (
    <>
      <ul className={styles.menu}>
        <li>
          <a href="https://linktr.ee/Kwarxs" target="_blank" rel="noreferrer">Contact Us</a>
        </li>
        <li>
          <Link to="/privacy">Privacy Policy</Link>
        </li>
        <li className={styles.terms}>
          <Link to="/terms">Terms & conditions</Link>
        </li>
      </ul>
      <div className={styles.copyright}>Kwarxs © 2025</div>
    </>
  );
};

export default FooterBottom;
