import { MlmType, WalletType } from "../../lib";
import { useAuthContext } from "../../context/AuthContext";
import { Button } from "../button";
import { Modal } from "../Modal";
import styles from "./index.module.scss";
import eternlIcon from "../../assets/icons/wallets/eternl.svg";
import laceIcon from "../../assets/icons/wallets/lace.svg";
import namiIcon from "../../assets/icons/wallets/nami.svg";
import vesprIcon from "../../assets/icons/wallets/vespr-dark.svg";
import tokeoIcon from "../../assets/icons/wallets/tokeo.png";
import { useEffect, useRef, useState } from "react";
import { useToastMessage } from "../../hooks/useToastMessage";
import { getConfig } from "../../config";
import CopyToClipboard from "react-copy-to-clipboard";

type WalletConnectorModalProps = {
  mlmType: MlmType;
  isOpen: boolean;
  onClose: () => void;
};

type ModalState = "connect" | "signup-pending" | "signup-started" | "hidden";

/**
 * A modal that allows the user to select between wallets to connect to.
 * Currently only Nami and Eternl are supported.
 */
export const WalletConnectorModal = ({
  mlmType,
  isOpen,
  onClose,
}: WalletConnectorModalProps) => {
  const {
    connectWallet,
    installedWallets,
    isLoading,
    connectedWallet,
    kwarxsUser,
    nfteamUser,
    fetchUserForWallet,
  } = useAuthContext();
  const toast = useToastMessage();
  const config = getConfig();
  const [ hasSignupStarted, setHasSignupStarted ] = useState<boolean>(false);
  const [ isCheckboxChecked, setIsCheckboxChecked ] = useState<boolean>(false);
  const checkboxContainerRef = useRef<HTMLDivElement>(null);

  const isUserMissing =
    (mlmType === "KWARXS" && !kwarxsUser) ||
    (mlmType === "NFTEAM" && !nfteamUser);

  const getModalState = (): ModalState => {
    if (isLoading || !isUserMissing) return "hidden";
    if (!connectedWallet) return "connect";
    if (hasSignupStarted) return "signup-started";

    return "signup-pending";
  };

  useEffect(() => {
    // The user cannot start the signup process until they have connected a wallet
    if (!connectedWallet) setHasSignupStarted(false);

    // Once user is found, the signup flow is complete
    if (!isUserMissing) onClose();
  }, [ connectedWallet, isUserMissing ]);

  const onWalletClick = (wallet: WalletType) => async () => {
    if (!isCheckboxChecked) {
      if (checkboxContainerRef.current) {
        checkboxContainerRef.current.classList.add(styles.flash);
        setTimeout(() => {
          checkboxContainerRef.current?.classList.remove(styles.flash);
        }, 600);
      }
      return;
    }

    try {
      await connectWallet(wallet);
    } catch (e: any) {
      toast.error(e.message);
      onClose();
    }
  };

  const onSignUpClick = () => {
    setHasSignupStarted(true);
    const signUpUrl =
      mlmType === "KWARXS" ? config.kwarxsSignupUrl : config.nfteamSignupUrl;
    window.open(signUpUrl, "_blank");
  };

  const onCompletedSignupClick = async () => {
    const foundUser = connectedWallet
      ? await fetchUserForWallet(connectedWallet, mlmType)
      : null;

    if (!foundUser) {
      toast.error(
        "No user found with your wallet address. Please try signing up again."
      );
    }
  };

  const renderWalletButton = (
    wallet: WalletType,
    icon: string,
    isInstalled: boolean,
    isRounded?: boolean
  ) => (
    <Button
      onClick={onWalletClick(wallet)}
      disabled={isLoading || !isInstalled}
    >
      <img
        src={icon}
        alt={wallet}
        style={{ borderRadius: isRounded ? "15px" : "0" }}
      />
      <span>Connect with {wallet}</span>
    </Button>
  );

  const renderContent = () => {
    switch (getModalState()) {
      case "connect":
        return (
          <>
            <h2>Connect Wallet</h2>
            <div className={styles.checkboxContainer} ref={checkboxContainerRef}>
              <input
                type="checkbox"
                id="termsCheckbox"
                checked={isCheckboxChecked}
                onChange={(e) => setIsCheckboxChecked(e.target.checked)}
              />
              <label htmlFor="termsCheckbox">
                By connecting your wallet, you agree to our{" "}
                <a href="/terms" target="_blank">
                  Terms & Conditions
                </a>{" "}
                and our{" "}
                <a href="/privacy" target="_blank">
                  Privacy Policy
                </a>
                .
              </label>
            </div>
            <div className={styles.buttonContainer}>
              {renderWalletButton("Nami", namiIcon, installedWallets.nami)}
              {renderWalletButton(
                "Eternl",
                eternlIcon,
                installedWallets.eternl
              )}
              {renderWalletButton("VESPR", vesprIcon, installedWallets.vespr)}
              {renderWalletButton("Lace", laceIcon, installedWallets.lace)}
              {renderWalletButton(
                "Tokeo",
                tokeoIcon,
                installedWallets.tokeo,
                true
              )}
            </div>
          </>
        );
      case "signup-pending":
        return (
          <>
            <h2>Create Account</h2>
            <p>
              You'll be directed to a separate signup form. Please use this as
              your Ada Wallet Address when prompted:
            </p>
            {connectedWallet && (
              <>
                <div className={styles.addressContainer}>
                  <code>{connectedWallet.bech32}</code>
                  <CopyToClipboard text={connectedWallet.bech32}>
                    <button className={styles.copyButton}>Copy</button>
                  </CopyToClipboard>
                </div>
                <p>
                  Once you complete the signup process, you can return here to
                  continue.
                </p>
              </>
            )}
            <div className={styles.buttonContainer}>
              <Button onClick={onSignUpClick}>Sign Up</Button>
            </div>
          </>
        );
      case "signup-started":
        return (
          <>
            <h2>Create Account</h2>
            <Button onClick={onCompletedSignupClick}>
              I've completed the sign up form
            </Button>
            <Button secondary onClick={onSignUpClick}>
              Return to signup page
            </Button>
          </>
        );
    }
  };

  return (
    <Modal showModal={isOpen && getModalState() !== "hidden"} onClose={onClose}>
      <div className={styles.modalContent}>{renderContent()}</div>
    </Modal>
  );
};

export default WalletConnectorModal;
