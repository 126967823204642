import { useState } from "react";
import { Layout } from "../../components/layout";
import AddCollectionModal from "./AddCollectionModal";
import CollectionsTable from "./CollectionsTable";
import styles from "./index.module.scss";
import { Button } from "../../components/button"; // Assuming the Button component is located here
import { FaPlus } from "react-icons/fa6";

function AdminPage() {
  const [ isAddCollectionModalOpen, setIsAddCollectionModalOpen ] =
    useState(false);

  const openAddCollectionModal = () => {
    setIsAddCollectionModalOpen(true);
  };

  const closeAddCollectionModal = () => {
    setIsAddCollectionModalOpen(false);
  };

  return (
    <Layout mlmType="KWARXS">
      <div className={styles.admin__container}>
        <div className={styles.admin__header}>
          <Button onClick={openAddCollectionModal}>
            <FaPlus /> Add Collection
          </Button>
        </div>
        <CollectionsTable />
        <AddCollectionModal
          isOpen={isAddCollectionModalOpen}
          onClose={closeAddCollectionModal}
        />
      </div>
    </Layout>
  );
}

export default AdminPage;
