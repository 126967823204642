import { MlmType, UserInfo, WalletType } from "..";

// Store minimum details about wallet
export type StoredWalletInfo = {
  walletType: WalletType,
}

const storageKeys = {
  kwarxsUser: "kwarxs-user",
  nfteamUser: "nfteam-user",
  wallet: "wallet",
} as const;

export const walletStorage = {
  get: (): StoredWalletInfo | null => {
    const walletInfo = localStorage.getItem(storageKeys.wallet);
    return walletInfo ? JSON.parse(walletInfo) : null;
  },
  set:(walletInfo: StoredWalletInfo) => {
    // Ensure that no extra details about the wallet get stored if they are
    // passed in with the wallet info.
    const minimumWalletIfno = { walletType: walletInfo.walletType };

    localStorage.setItem(storageKeys.wallet, JSON.stringify(minimumWalletIfno));
  },
  remove: () => {
    localStorage.removeItem(storageKeys.wallet);
  },
};

export const userStorage = {
  set: (mlmType: MlmType, UserInfo: UserInfo) => {
    const storageKey = getUserStorageKey(mlmType);
    localStorage.setItem(storageKey, JSON.stringify(UserInfo));
  },
  get: (mlmType: MlmType): UserInfo | null => {
    const storageKey = getUserStorageKey(mlmType);
    const userInfo = localStorage.getItem(storageKey);
    return userInfo ? JSON.parse(userInfo) : null;
  },
  remove: (mlmType: MlmType) => {
    const storageKey = getUserStorageKey(mlmType);
    localStorage.removeItem(storageKey);
  },
};

function getUserStorageKey(mlmType: MlmType) {
  return mlmType === "KWARXS" ? storageKeys.kwarxsUser : storageKeys.nfteamUser;
}
