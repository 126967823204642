import React, {
  createContext,
  useState,
  useMemo,
  PropsWithChildren,
  useEffect,
} from "react";
import {
  getUserForWallet,
  MlmType,
  UserInfo,
  Wallet,
  WalletNotConnectedError,
  WalletType,
} from "../lib";
import { getConfig } from "../config";
import {
  getPrimaryWalletAddress,
  getWalletAddresses,
  getWalletAvailability,
} from "../lib/utils/wallet";
import { userStorage, walletStorage } from "../lib/utils/storage";

interface AuthContextType {
  nfteamUser: UserInfo | null;
  kwarxsUser: UserInfo | null;
  connectedWallet: Wallet | null;
  isLoading: boolean;
  installedWallets: {
    nami: boolean;
    eternl: boolean;
    vespr: boolean;
    lace: boolean;
    tokeo: boolean;
  };
  connectWallet: (walletType: WalletType) => Promise<Wallet | null>;
  disconnectWallet: () => void;
  fetchUserForWallet: (
    wallet: Wallet,
    mlmType: MlmType
  ) => Promise<UserInfo | null>;
}

export const AuthContext = createContext<AuthContextType>({
  nfteamUser: null,
  kwarxsUser: null,
  connectedWallet: null,
  isLoading: false,
  installedWallets: {
    nami: false,
    eternl: false,
    vespr: false,
    lace: false,
    tokeo: false,
  },
  connectWallet: async () => null,
  disconnectWallet: async () => {},
  fetchUserForWallet: async () => null,
});

const network = getConfig().cardanoNetwork;
const installedWallets = getWalletAvailability();

let timer: NodeJS.Timer;

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [ hasInitialized, setHasInitialized ] = useState(false);
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ connectedWallet, setConnectedWallet ] = useState<Wallet | null>(null);
  const [ nfteamUser, setNfteamUser ] = useState<UserInfo | null>(null);
  const [ kwarxsUser, setKwarxsUser ] = useState<UserInfo | null>(null);

  const setUser = (mlmType: MlmType, user: UserInfo | null) => {
    return mlmType === "KWARXS" ? setKwarxsUser(user) : setNfteamUser(user);
  };

  const withLoading = async <T,>(options: {
    label: string;
    fn: () => Promise<T>;
    onError?: (e: any) => void;
  }) => {
    setIsLoading(true);
    try {
      const result = await options.fn();
      return result;
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.log(options.label, e);
      options.onError?.(e);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  // Validates that the stored user matches the connected wallet.
  // If not, the user will be re-fetched and updated.
  // The correct user will be returned.
  const validateStoredUser = async (
    connectedWallet: Wallet | null,
    mlmType: MlmType
  ): Promise<UserInfo | null> => {
    const storedUser = userStorage.get(mlmType);

    const walletAddresses = connectedWallet
      ? await getWalletAddresses(connectedWallet.api)
      : [];
    const storedUserAddress = storedUser ? storedUser.walletAddress.hex : null;

    if (storedUserAddress && walletAddresses.includes(storedUserAddress)) {
      // User is still valid
      return storedUser;
    } else if (connectedWallet) {
      // Wallet is connected but user doesn't match and requires re-fetch
      const user = await getUserForWallet(connectedWallet, mlmType);
      if (user) {
        userStorage.set(mlmType, user);
      } else {
        userStorage.remove(mlmType);
      }
      return user;
    } else {
      // Wallet is disconnected, so user should be removed
      userStorage.remove(mlmType);
      return null;
    }
  };

  /**
   * Connects the specified user wallet to the dapp
   */
  const connectWallet = async (
    walletType: WalletType
  ): Promise<Wallet | null> => {
    return withLoading({
      label: "connectWallet",
      fn: async () => {
        const walletNamespace = window.cardano?.[walletType.toLowerCase()];

        if (!walletNamespace) {
          throw new Error(`${walletType} wallet not found`);
        }

        const wallet = await walletNamespace.enable();
        const addressDetails = await getPrimaryWalletAddress(wallet);

        if (!addressDetails) {
          throw new Error("No wallet address found");
        }

        const walletNetworkId = await wallet.getNetworkId();
        const expectedNetworkId = network === "Mainnet" ? 1 : 0;

        if (walletNetworkId !== expectedNetworkId) {
          throw new Error(
            `Your wallet is connected to the wrong network. Please connect to ${network}.`
          );
        }

        const newWallet = {
          walletType,
          pkh: addressDetails.paymentCredential!.hash,
          skh: addressDetails.stakeCredential?.hash,
          bech32: addressDetails.address.bech32,
          hex: addressDetails.address.hex,
          api: wallet,
        };

        // Fetch users and update state together to prevent temporary
        // inconsistencies in the UI (e.g., flashing signup modal)
        const newKwarxsUser = await validateStoredUser(newWallet, "KWARXS");
        const newNfteamUser = await validateStoredUser(newWallet, "NFTEAM");

        walletStorage.set(newWallet);
        setConnectedWallet(newWallet);

        setKwarxsUser(newKwarxsUser);
        setNfteamUser(newNfteamUser);

        return newWallet;
      },
      onError: (e) => {
        walletStorage.remove();
        setConnectedWallet(null);
      },
    });
  };

  const disconnectWallet = async () => {
    return withLoading({
      label: "disconnectWallet",
      fn: async () => {
        setConnectedWallet(null);
        setNfteamUser(null);
        setKwarxsUser(null);

        walletStorage.remove();
        userStorage.remove("KWARXS");
        userStorage.remove("NFTEAM");
      },
    });
  };

  const refreshWalletConnection = async (): Promise<void> => {
    if (!connectedWallet) return;

    const walletType = connectedWallet.walletType;
    const primaryAddress = await getPrimaryWalletAddress(connectedWallet.api);

    // If no address is found, the user may have revoked access to the wallet.
    // Regardless of the reason, we should disconnect the wallet.
    if (!primaryAddress) {
      disconnectWallet();
      return;
    }

    // If the address has changed, the user may have switched accounts.
    // In this case, we should refresh the connection.
    if (connectedWallet.hex !== primaryAddress.address.hex) {
      await connectWallet(walletType);
    }
  };

  const fetchUserForWallet = async (
    wallet: Wallet,
    mlmType: MlmType
  ): Promise<UserInfo | null> => {
    return withLoading({
      label: "fetchUserForWallet",
      fn: async () => {
        if (!wallet) {
          throw new WalletNotConnectedError();
        }

        const foundUser = await getUserForWallet(wallet, mlmType);

        if (!foundUser) {
          userStorage.remove(mlmType);
          setUser(mlmType, null);
          return null;
        }

        userStorage.set(mlmType, foundUser);
        setUser(mlmType, foundUser);

        return foundUser;
      },
    });
  };

  /**
   * Fetches wallet and user info from local storage, verifies that this data
   * is still valid, and updates the connection state accordingly.
   */
  const initialize = async () => {
    try {
      const storedWallet = walletStorage.get();

      if (storedWallet) {
        await connectWallet(storedWallet.walletType);
      }
    } catch (error) {
      // If anything fails, reset everything to be safe
      walletStorage.remove();
      userStorage.remove("KWARXS");
      userStorage.remove("NFTEAM");
      setConnectedWallet(null);
      setNfteamUser(null);
      setKwarxsUser(null);
    } finally {
      setHasInitialized(true);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  // Each time the connected wallet changes, update the connection polling and
  // fetch related user info as necessary
  useEffect(() => {
    if (!hasInitialized) return;

    if (timer) {
      clearInterval(timer);
    }

    if (connectedWallet) {
      // Poll for any changes in the connected wallet state
      timer = setInterval(() => {
        refreshWalletConnection();
      }, 3000);
    }
  }, [ hasInitialized, connectedWallet ]);

  const contextValue = useMemo(() => {
    return {
      kwarxsUser,
      nfteamUser,
      connectedWallet,
      isLoading,
      connectWallet,
      disconnectWallet,
      installedWallets,
      fetchUserForWallet,
    };
  }, [
    kwarxsUser,
    nfteamUser,
    connectedWallet,
    isLoading,
    connectWallet,
    disconnectWallet,
    installedWallets,
    fetchUserForWallet,
  ]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuthContext = () => React.useContext(AuthContext);
