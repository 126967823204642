type AppConfig = {
  apiBaseUrl: string;
  cardanoNetwork: "Preview" | "Preprod" | "Mainnet";
  rhinoxCurrencySymbol: string;
  nfteamSignupUrl: string;
  kwarxsSignupUrl: string;
  blockpassClientId: string;
  inviteCode: {
    default: string;
    storageKey: string;
  };
};

const REACT_APP_API_BASE_URL = "REACT_APP_API_BASE_URL";
const CARDANO_NETWORK = "REACT_APP_CARDANO_NETWORK";
const RHINOX_CURRENCY_SYMBOL = "REACT_APP_RHINOX_CURRENCY_SYMBOL";
const NFTEAM_SIGNUP_URL = "REACT_APP_NFTEAM_SIGNUP_URL";
const KWARXS_SIGNUP_URL = "REACT_APP_KWARXS_SIGNUP_URL";
const BLOCKPASS_CLIENT_ID = "REACT_APP_BLOCKPASS_CLIENT_ID";
const DEFAULT_INVITE_CODE = "REACT_APP_DEFAULT_INVITE_CODE";

const makeErrorMsg = (varName: string) => `Environment variable '${varName}' is missing or invalid!`;

let config: AppConfig | undefined;

export const getConfig = () => {
  if (!config) {
    // Remove any trailing slashes from the API base URL
    const apiBaseUrl = process.env[REACT_APP_API_BASE_URL]?.replace(/\/+$/, "");

    if (!apiBaseUrl || !isValidURL(apiBaseUrl)) {
      throw new Error(makeErrorMsg(REACT_APP_API_BASE_URL));
    }

    const cardanoNetwork = process.env[CARDANO_NETWORK];
    if (cardanoNetwork !== "Preview" && cardanoNetwork !== "Preprod" && cardanoNetwork !== "Mainnet") {
      throw new Error(makeErrorMsg(CARDANO_NETWORK));
    }

    const rhinoxCurrencySymbol = process.env[RHINOX_CURRENCY_SYMBOL];
    if (!rhinoxCurrencySymbol) {
      throw new Error(makeErrorMsg(RHINOX_CURRENCY_SYMBOL));
    }

    const nfteamSignupUrl = process.env[NFTEAM_SIGNUP_URL];
    if (!nfteamSignupUrl) {
      throw new Error(makeErrorMsg(NFTEAM_SIGNUP_URL));
    }

    const kwarxsSignupUrl = process.env[KWARXS_SIGNUP_URL];
    if (!kwarxsSignupUrl) {
      throw new Error(makeErrorMsg(KWARXS_SIGNUP_URL));
    }

    const blockpassClientId = process.env[BLOCKPASS_CLIENT_ID];
    if (!blockpassClientId) {
      throw new Error(makeErrorMsg(BLOCKPASS_CLIENT_ID));
    }

    const defaultInviteCode = process.env[DEFAULT_INVITE_CODE];
    if (defaultInviteCode === undefined) {
      throw new Error(makeErrorMsg(DEFAULT_INVITE_CODE));
    }

    config = {
      apiBaseUrl,
      cardanoNetwork,
      rhinoxCurrencySymbol,
      nfteamSignupUrl,
      kwarxsSignupUrl,
      blockpassClientId,
      inviteCode: {
        default: defaultInviteCode,
        storageKey: "inviteCode",
      },
    };
  }

  return config;
};

function isValidURL(url: string) {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
}
