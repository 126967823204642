import {
  TxHash,
  Data,
  getAddressDetails,
  WalletApi,
} from "lucid-cardano";
import {
  BuyNftParams,
  Nft,
  WithdrawRewardParams,
  WalletNotConnectedError,
  GetRewardPoolsByAssetParams,
  CreateRewardPoolParams,
  RewardPoolWithUTxO,
  MlmType,
  Wallet,
} from "./types/domain";
import {
  getMetadataPolicyIdTokenName,
  getNfts,
  getRewardsAsset,
  getRewardsNfts,
  postAccountsWallet,
  postNftsBuy,
  postNftsCreate,
  postNftsCreateNft,
  postRewards,
  postRewardsWithdraw,
} from "./api/generated/client";
import {
  Collection,
  CreateCollection,
  GYValue,
  PostNftsCreateNftBodyItem,
  RewardPoolNft,
  UserInfo,
} from "./api/generated/models";
import { mkGYAssetClass } from "./utils/encoding";
import { getDefaultRequestParams, getWalletAddresses, signAndSubmitTx } from "./utils/wallet";

export * from "./types/domain";
export * from "./api/generated/models";

// TODO: Remove dummy Kwarxs user once Kwarxs MLM integration is working correctly
export const getUserForWallet = async (
  wallet: Wallet,
  mlmType: MlmType
): Promise<UserInfo | null> => {
  const addresseses = await getWalletAddresses(wallet.api);

  if (!addresseses) {
    throw new WalletNotConnectedError();
  }

  if (mlmType === "KWARXS") {
    return {
      accountId: 0,
      walletAddress: {
        hex: wallet.hex,
        bech32: wallet.bech32,
      },
    };
  }

  const res = await postAccountsWallet(addresseses, {
    nfteam: mlmType === "NFTEAM",
  });

  if (res.status === 200) {
    return {
      accountId: res.data.accountId,
      walletAddress: {
        hex: res.data.walletAddress,
        bech32: getAddressDetails(res.data.walletAddress).address.bech32,
      },
    };
  }
  if (res.status === 404) return null;

  throw new Error(`Failed to fetch user info: ${res.data}`);
};

/**
 * Adds new NFT collections to the marketplace.
 * @param collections List of collections to add
 */
export const addCollections = async (
  collections: CreateCollection[]
): Promise<void> => {
  await postNftsCreate(collections);
};

/**
 * Fetches all NFT collections available in the marketplace.
 * @returns List of NFT collections
 */
export const getCollections = async (): Promise<Collection[]> => {
  const res = await getNfts();

  if (res.status !== 200) {
    throw new Error(`Failed to fetch NFT collections: ${res.data}`);
  }

  return res.data;
};

/**
 * Adds new NFTs to the marketplace.
 * @param nfts List of NFTs to add
 */
export const addNft = async (nfts: PostNftsCreateNftBodyItem[]): Promise<void> => {
  await postNftsCreateNft(nfts);
};

/**
 * Buys an NFT using the connected wallet.
 *
 * @param {BuyNftParams} params - The parameters for buying the NFT.
 * @param {string} params.currencySymbol - The currency symbol of the NFT to be bought.
 * @returns {Promise<TxHash>} - A promise that resolves to the transaction hash of the submitted transaction.
 * @throws {Error} - Throws an error if the user wallet is not connected.
 * @throws {UserCancelledError} - Throws an error if the user cancels the transaction signing.
 * @throws {Error} - Throws an error for any other failure during the transaction process.
 */
export const buyNft = async ({
  wallet,
  user,
  currencySymbol,
}: BuyNftParams): Promise<TxHash> => {
  const defaultParams = await getDefaultRequestParams(wallet, user);
  const response = await postNftsBuy({
    currencySymbol,
    ...defaultParams,
  });

  if (response.status !== 200) {
    throw new Error(`Failed to buy NFT: ${response.data}`);
  }

  return signAndSubmitTx(wallet.api, response.data.unsignedTx);
};

/** Get user's NFTs and it's metadata
 * @param currencySymbol Currency symbol of NFT collection
 * @returns List of user's NFTs
 */
export const getUsersNfts = async (params: {
  walletApi: WalletApi;
  currencySymbol: string;
}): Promise<Nft[]> => {
  const walletBalance = await params.walletApi.getBalance();

  const walletBalanceData = Data.from(walletBalance);

  if (!Array.isArray(walletBalanceData)) {
    return [];
  }

  const assetsMap = walletBalanceData[1];

  if (!(assetsMap instanceof Map)) {
    return [];
  }

  const res: Nft[] = [];

  for (const [ cs, tokens ] of assetsMap) {
    if (cs !== params.currencySymbol || !(tokens instanceof Map)) {
      continue;
    }

    for (const [tn] of tokens) {
      if (typeof tn !== "string") continue;

      const response = await getMetadataPolicyIdTokenName(cs, tn);

      if (response.status === 200 && response.data) {
        res.push({
          currencySymbol: cs,
          tokenName: tn,
          metadata: response.data,
        });
      }
    }
  }
  return res;
};

/** Create reward pools
 * @returns Hash of submitted transaction
 */
export const createRewardPools = async (
  params: CreateRewardPoolParams
): Promise<TxHash> => {
  const defaultParams = await getDefaultRequestParams(
    params.wallet,
    params.user,
  );
  const res = await postRewards({
    cpCounter: params.counter,
    cpRewardNftsPerPool: params.dividendsPerPool,
    cpRewardNftCs: params.nftCs,
    cpPoolCount: params.poolCount,
    cpPoolTn: params.poolTn,
    cpRewardPerNft: params.rewardValue,
    ...defaultParams,
  });

  return signAndSubmitTx(params.wallet.api, res.data.unsignedTx);
};

export const getRewardNfts = async (): Promise<RewardPoolNft[]> => {
  const res = await getRewardsNfts();
  return res.data;
};

/** Withdraw part of reward from the pool of rewards
 * @param param Reward to withdraw with list of NFTs to remint
 * @returns Hash of submitted transaction
 */
export const withdrawReward = async (
  params: WithdrawRewardParams
): Promise<TxHash> => {
  const defaultParams = await getDefaultRequestParams(
    params.wallet,
    params.kwarxsUser
  );

  const response = await postRewardsWithdraw({
    wpCollection: params.collectionPolicyId,
    wpRewardNfts: params.nftTokenNames,
    wpPoolRef: params.pool.poolUTxO[0] as string,
    ...defaultParams,
  });

  if (response.status !== 200) {
    throw new Error(`Failed to withdraw reward: ${response.data}`);
  }

  return signAndSubmitTx(params.wallet.api, response.data.unsignedTx);
};

/** List available pools of rewards for the given asset
 */
export const getRewardPoolsByAsset = async (
  params: GetRewardPoolsByAssetParams
): Promise<RewardPoolWithUTxO[]> => {
  const assetClass = mkGYAssetClass(params);
  const res = await getRewardsAsset(assetClass);

  if (res.status !== 200) {
    throw new Error(`Failed to fetch reward pools: ${res.data}`);
  }

  return res.data as RewardPoolWithUTxO[];
};

export const extractClaimableAmount = (
  pool: RewardPoolWithUTxO,
  nftQty = 1
): GYValue => {
  // Get the datum from the UTXO (third element in poolUTxO array)
  const datum = pool.poolUTxO[2];

  const result: GYValue = {};

  const valueMap = datum!.fields[3].map;

  for (const outerEntry of valueMap) {
    // The outer key is the policy ID (empty string means ADA)
    const policyId = outerEntry.k.bytes;

    // Process inner map (token names to amounts)
    const innerMap = outerEntry.v.map;
    for (const innerEntry of innerMap) {
      // The inner key is the asset name
      const assetName = innerEntry.k.bytes;
      const amount = innerEntry.v.int;

      // Create the asset class key
      const assetClass =
        policyId === "" && assetName === ""
          ? "lovelace" // Special case for ADA
          : `${policyId}${assetName ? "." + assetName : ""}`;

      result[assetClass] = amount * nftQty;
    }
  }

  return result;
};
