export interface FaqContent {
  title: string;
  text: JSX.Element;
  id: string;
}

export const faqContent: FaqContent[] = [
  {
    title: "Why Kwarxs?",
    text: (
      <p>
        The Kwarxs protocol takes advantage of Cardano’s Plutus smart contracts.
        This provides strong transaction determinism and security guarantees
        found on no other blockchain.
      </p>
    ),
    id: "1",
  },
  {
    title: "What are Kwarxs NFTs?",
    text: (
      <p>
        These Legendary, Secret Rare, Ultra Rare, Uncommon, and Common NFTs
        represent the fractionalized ownership of a solar farm.
        <br />
        <br />
        Some of the rarer Kwarxs NFT attributes provide extra passive income. By
        holding these crypto assets as tokenized balances, you gain several
        benefits:
        <br />
        <br />
        1. Earn interest on the underlying assets <br /> 2. Keep your voting
        rights <br />
        3. Become part of a cooperative business <br />
        4. Show the world how to build a brighter future… together
      </p>
    ),
    id: "2",
  },
  {
    title: "Can I fractionalize my own business using the Kwarxs DAO protocol?",
    text: (
      <p>
        Upcoming Kwarxs dApp features provide a means by which you may
        fractionalize many real-world assets, including goods (i.e. artworks,
        collectibles, web domains, timeshare properties) and services (i.e.
        sustainable energy solutions, rental properties, restaurants).
      </p>
    ),
    id: "3",
  },
];
